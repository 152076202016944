<template>
  <div class="donut-size" :class="[size, { mobileMini }]">
    <div class="pie-wrapper">
      <div class="label">
        <div>
          <div class="top-label" v-if="size === 'large'">{{ $t('progress') }}</div>
          <div class="bottom-label" :style="progressColorStyle">{{ Math.round(progress, 1) }}%</div>
        </div>
      </div>
      <div class="pie" :style="pieStyle">
        <div class="left-side half-circle" :style="leftSideStyle"></div>
        <div class="right-side half-circle" :style="rightSideStyle"></div>
      </div>
      <div class="pie-shadow" :style="shadowStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressPie",
  props: {
    progress: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'large'
    },
    mobileMini: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    degrees() {
      return Math.round(360 * (this.progress / 100))
    },
    pieStyle() {
      return {
        'clip': this.progress > 50 ? 'rect(auto, auto, auto, auto)' : 'rect(0, 1em, 1em, 0.5em)'
      }
    },
    rightSideStyle() {
      return {
        'transform': this.progress > 50 ? 'rotate(180deg)' : 'rotate(0deg)',
        'borderColor': `${this.indicatorColor}`
      }
    },
    leftSideStyle() {
      return {
        'transform': `rotate(${this.degrees}deg)`,
        'borderColor': `${this.indicatorColor}`
      }
    },
    progressColorStyle() {
      return {
        'color': this.indicatorColor
      }
    },
    shadowStyle() {
      return {
        'border-color': this.colors[1] ?  this.colors[1] : '#E5E6E9'
      }
    },
    indicatorColor() {
      return this.colors[0] ? this.colors[0] : this.progress < 100 ? '#F2C94C' : '#27AE60'
    }
  }
}
</script>

<style scoped lang="scss">

.donut-size {
  .pie-wrapper {
    position: relative;
    width: 1em;
    height: 1em;
    margin: 0 auto;
    .pie {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip: rect(0, 1em, 1em, 0.5em);
    }
    .right-side, .left-side, .shadow {
      border-width: 12px;
    }
    .half-circle {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 12px solid;
      border-radius: 50%;
      clip: rect(0em, 0.5em, 1em, 0em);
    }
    .right-side {
      transform: rotate(0deg)
    }
    .label {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      z-index: 2;
      font-weight: 700;
      .top-label {
        font-size: 14px;
      }
    }
    .pie-shadow {
      width: 100%;
      height: 100%;
      border: 12px solid #E5E6E9;
      border-radius: 50%;
    }
  }
  &.large {
    font-size: 12em;
    .label {
      .bottom-label {
        font-size: 32px;
      }
    }
  }
  &.medium {
    font-size: 8em;
    .label {
      .bottom-label {
        font-size: 24px;
      }
    }
    .pie-wrapper {
      .right-side, .left-side, .shadow {
        border-width: 10px;
      }
      .pie-shadow {
        border: 10px solid #E5E6E9;
      }
    }
  }
  &.small {
    font-size: 4em;
    .label {
      .bottom-label {
        font-size: 16px;
      }
    }
    .pie-wrapper {
      .right-side, .left-side, .shadow {
        border-width: 5px;
      }
      .pie-shadow {
        border: 5px solid #E5E6E9;
      }
    }
  }
}
@media(max-width: 768px) {
  .mobileMini {
    .pie-shadow, .pie {
      display: none;
    }
    .pie-wrapper {
      width: auto;
      height: auto;
      .label {
        color: #7E828C;
        position: relative;
        .bottom-label {
          font-size: 20px !important;
        }
      }
    }
  }
}
</style>
