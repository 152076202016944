<template>
    <div class="spacecard border-0">
      <b-row class="align-items-center">
        <b-col cols="auto">
          <b-button class="btn-themed back-btn-squared btn-white" :to="{ name: 'StudentMediumCourses' }">
            <BackSvg/>
          </b-button>
        </b-col>
        <b-col>
          <h2>
            {{ mediumCourse.course.title }}
          </h2>
        </b-col>
        <b-col cols="auto">
          <ProgressPie size="small"
                       :progress="progress"/>
        </b-col>
      </b-row>
      <hr/>
      <section class="medium-courses" v-if="mediumCourse.selfStudyLessons.length">
        <div v-for="(selfStudyLesson, index) in mediumCourse.selfStudyLessons" :key="`self-${index}`" class="spacecard__card standart_card d-block">
            <h4 v-if="!isRenderedLevel(selfStudyLesson.material.level)">
              {{ selfStudyLesson.material.level.code }} {{ selfStudyLesson.material.level.title }}
            </h4>
            <div class="row align-items-center">
              <div class="col-auto">
                <LetterIcon :obj="selfStudyLesson.material"
                            v-if="selfStudyLesson.material"
                            :title="selfStudyLesson.material.title"
                            class="md student-icon mr-md-3 mr-1"/>
              </div>
              <div class="col">
                <div class="subject_text">
                  <h3 class="mb-0" v-if="selfStudyLesson.material">{{selfStudyLesson.material.title}}</h3>
                </div>
                <div class="mt-2" v-if="selfStudyLesson.finished || selfStudyLesson.reviewed">
                  <span v-if="selfStudyLesson.finished" class="done-span mr-2"><compSvg class="mr-1"/>{{ $t('done') }}</span>
                  <span v-if="selfStudyLesson.reviewed" class="reviewed-span"><correctSvg class="mr-1"/>{{ $t('reviewed') }}</span>
                </div>
                <div v-else>
                  <span class="closed-span mt-2" v-if="!selfStudyLesson.availableForStudy"><LockSvg class="mr-2"/>{{ $t('lesson_closed') }}</span>
                </div>
              </div>
              <div class="col-auto">
                <button class="btn-themed btn-white repeat"
                        :disabled="!selfStudyLesson.availableForStudy"
                        @click="selfStudy(selfStudyLesson.material)">
                  {{ selfStudyLesson.finished ? $t('view') : $t('start') }}
                </button>
              </div>
            </div>
          </div>
      </section>
    </div>
  </template>
  
  <script>
  import { StudentPageService } from "../../../../services/api.service"
  import compSvg from '@/assets/svg/completed.svg'
  import correctSvg from '@/assets/svg/completed_green.svg'
  import LockSvg from "@/assets/svg/lock.svg"
  import LetterIcon from '@/views/parts/general/LetterIcon'
  import BackSvg from '@/assets/svg/back_3.svg'
  import ProgressPie from "../../../parts/general/ProgressPie.vue"

  export default {
    name: "StudentMediumCourses",
    components: {
      compSvg,
      correctSvg,
      LockSvg,
      LetterIcon,
      ProgressPie,
      BackSvg
    },
    data() {
        return {
            mediumCourse: null,
        }
    },
    computed: {
        courseId() {
            return this.$route.params.courseId
        },
        progress() {
          return this.mediumCourse.selfStudyLessons.reduce((partialSum, a) => partialSum + a.progress, 0) / this.mediumCourse.selfStudyLessons.length;
        }
    },
    methods: {
      async loadCourseLessons() {
        const res = await StudentPageService.homeSelfStudy(this.courseId);
        this.mediumCourse = res.data.data.mediumCourses[0];
        this.$renderedLevels = [];
      },
      selfStudy(material) {
        this.$router.push({
          name: 'SelfStudyLesson',
          params: { id: material.id }
        })
      },
      isRenderedLevel(level) {
        if (this.$renderedLevels.includes(level.id)) {
          return true;
        }
        this.$renderedLevels.push(level.id);
        return false;
      }
    },
    mounted() {
      this.loadCourseLessons()
    },
    created() {
      this.$renderedLevels = []
    }
  }
  </script>
  
<style scoped lang="scss">
  h2 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 700;
    margin-left: 6px;
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media(max-width: 768px) {
    h2 {
      font-size: 16px;
    }
  }
  .standart_card {
    cursor: pointer;
    &:hover {
      background: #F8F9FB;
    }
  }
  .spacecard {
    padding: 16px;
    &__card {
      padding: 24px;
      border-radius: 16px;
    }
  }
  @media(max-width: 768px) {
    .spacecard {
      padding: 12px;
      &__card {
        padding: 12px;
      }
      h3 {
        font-size: 14px;
      }
    }
  }
  </style>
  